/**
 * 自动生成的代码，请勿修改
 */
import { doRequest } from './utils';
import type { ResponseType } from './utils';
import type {
  ListRankTypeRequest,
  ResponseListRankTypeResponse,
  ListRankListRequest,
  ResponseListRankListResponse,
  PageSpecialTopicConfigEsRequestDTO,
  ResponseBaseEsPageResponseDTOAttSpecialTopicConfigDTO,
  GetSpecialTopicConfigRequestDTO,
  ResponseBaseSingleResponseDTOAttSpecialTopicConfigDetailResponseDTO,
  GetAttExperienceJumpUrlRequest,
  ResponseGetAttJumpUrlResponse,
} from '../types';

const baseUrl = '';

class AttrRankControllerService {
  /**
   * @summary 查询榜单type*
   * @fullUrl /rank/type/list
   */
  public rankTypeListPost(
    body: ListRankTypeRequest,
  ): ResponseType<ResponseListRankTypeResponse> {
    const requestUrl = baseUrl + '/rank/type/list';
    const res = doRequest<ResponseListRankTypeResponse>({
      method: 'post',
      url: requestUrl,
      body,
    });
    return res;
  }
  /**
   * @summary 查询榜单type下的景区列表*
   * @fullUrl /rank/list
   */
  public rankListPost(
    body: ListRankListRequest,
  ): ResponseType<ResponseListRankListResponse> {
    const requestUrl = baseUrl + '/rank/list';
    const res = doRequest<ResponseListRankListResponse>({
      method: 'post',
      url: requestUrl,
      body,
    });
    return res;
  }
  /**
   * @summary page att special topic config
   * @fullUrl /rank/pageAttSpecialTopicConfig
   */
  public rankPageAttSpecialTopicConfigPost(
    body: PageSpecialTopicConfigEsRequestDTO,
  ): ResponseType<ResponseBaseEsPageResponseDTOAttSpecialTopicConfigDTO> {
    const requestUrl = baseUrl + '/rank/pageAttSpecialTopicConfig';
    const res = doRequest<ResponseBaseEsPageResponseDTOAttSpecialTopicConfigDTO>({
      method: 'post',
      url: requestUrl,
      body,
    });
    return res;
  }
  /**
   * @summary getSpecialTopicConfigDetail
   * @fullUrl /rank/getSpecialTopicConfigDetail
   */
  public rankGetSpecialTopicConfigDetailPost(
    body: GetSpecialTopicConfigRequestDTO,
  ): ResponseType<ResponseBaseSingleResponseDTOAttSpecialTopicConfigDetailResponseDTO> {
    const requestUrl = baseUrl + '/rank/getSpecialTopicConfigDetail';
    const res = doRequest<ResponseBaseSingleResponseDTOAttSpecialTopicConfigDetailResponseDTO>({
      method: 'post',
      url: requestUrl,
      body,
    });
    return res;
  }
  /**
   * @summary getExperiencesJumpUrl
   * @fullUrl /rank/getExperiencesJumpUrl
   */
  public rankGetExperiencesJumpUrlPost(
    body: GetAttExperienceJumpUrlRequest,
  ): ResponseType<ResponseGetAttJumpUrlResponse> {
    const requestUrl = baseUrl + '/rank/getExperiencesJumpUrl';
    const res = doRequest<ResponseGetAttJumpUrlResponse>({
      method: 'post',
      url: requestUrl,
      body,
    });
    return res;
  }
}

export const attrRankController = new AttrRankControllerService();
